export const LogoSvg = () => (
    <svg width="261" height="32" viewBox="0 0 261 32" fill="none" xmlns="http://www.w3.org/2000/svg"
         className="w-[200px]">
        <path d="M6.4 19.2V12.8V6.4H12.8V0H6.4H0V6.4V12.8V19.2V25.6V32H6.4H12.8V25.6H6.4V19.2Z" fill="#F75231"></path>
        <path d="M19.2008 12.8004V6.40039H12.8008V12.8004V19.2004V25.6004H19.2008V19.2004V12.8004Z"
              fill="#F75231"></path>
        <path d="M25.5992 0H19.1992V6.4H25.5992V12.8V19.2V25.6H19.1992V32H25.5992H31.9992V25.6V19.2V12.8V6.4V0H25.5992Z"
              fill="#F75231"></path>
        <path
            d="M55.0873 25.5396C57.926 25.5396 59.7665 24.347 59.7665 21.9102C59.7665 19.3178 57.4852 18.6438 54.18 17.6717C49.9026 16.3755 45.6511 14.9108 45.6122 9.75201C45.6122 4.78763 50.1229 2.42871 54.867 2.42871C60.0517 2.42871 63.7977 4.85247 64.3421 10.5946H59.4814C59.0148 7.75592 57.563 6.45982 54.7633 6.45982C52.3265 6.45982 50.4081 7.43192 50.4081 9.82985C50.4081 11.9686 52.5079 12.9537 55.8521 13.9258C60.2461 15.222 64.5624 16.4014 64.5624 21.9102C64.5624 27.2116 59.8832 29.5707 55.0873 29.5707C49.423 29.5707 44.9641 26.7449 44.5234 20.614H49.4489C49.6822 23.8026 51.9635 25.5396 55.0873 25.5396Z"
            fill="currentColor"></path>
        <path
            d="M76.0968 29.5324C70.4325 29.5324 66.4792 25.5143 66.4792 19.5259C66.4183 18.2325 66.6244 16.9404 67.0844 15.7301C67.5445 14.5197 68.2487 13.417 69.1533 12.4906C70.0579 11.5641 71.1434 10.8337 72.3425 10.3449C73.5415 9.85603 74.8283 9.61911 76.1228 9.649C82.4092 9.649 85.2738 14.4059 85.3127 19.9536V20.861H70.938C70.9885 22.1825 71.5509 23.4327 72.5064 24.347C73.4619 25.2614 74.7355 25.7683 76.0579 25.7606C76.928 25.8589 77.8074 25.6894 78.5786 25.2748C79.3498 24.8603 79.9763 24.2203 80.3742 23.4404H85.0146C83.8998 27.1734 80.6335 29.5324 76.0968 29.5324ZM80.8149 17.7372C80.7843 16.5052 80.2663 15.3356 79.3745 14.4852C78.4827 13.6347 77.29 13.1727 76.0579 13.2005C74.7922 13.1661 73.5612 13.6167 72.6166 14.46C71.6721 15.3033 71.0855 16.4756 70.9769 17.7372H80.8149Z"
            fill="currentColor"></path>
        <path
            d="M94.6832 13.0962C93.1277 13.0962 91.8186 13.9257 91.8186 15.17C91.8186 16.4144 92.8426 17.0495 94.8646 17.5939C95.746 17.8531 96.5367 18.0346 97.457 18.255C100.659 19.0845 102.758 20.6399 102.758 23.5822C102.758 27.1727 99.3494 29.5317 94.9165 29.5317C90.8594 29.5317 87.3727 27.6523 86.8672 23.1416H91.4427C91.4813 23.5659 91.6046 23.9782 91.8052 24.3541C92.0059 24.73 92.2798 25.0619 92.6109 25.3302C92.942 25.5984 93.3235 25.7976 93.7328 25.9159C94.1422 26.0342 94.5711 26.0693 94.9942 26.0191C96.7311 26.0191 98.1829 25.2932 98.1829 23.7637C98.1829 22.2342 96.77 21.8454 95.3572 21.4824C94.4499 21.1843 93.5425 20.977 92.5963 20.7177C89.5503 19.9141 87.4764 18.1252 87.4764 15.2348C87.4764 11.9036 90.678 9.60938 94.7739 9.60938C99.0643 9.60938 101.994 11.787 102.473 15.7792H98.1181C98.008 14.9726 97.5874 14.2408 96.9459 13.7397C96.3043 13.2385 95.4924 13.0076 94.6832 13.0962Z"
            fill="currentColor"></path>
        <path
            d="M113.31 9.61069C119.182 9.61069 121.359 12.9938 121.359 17.5952V24.3872C121.348 25.92 121.469 27.4509 121.722 28.9627H117.043C116.944 28.3238 116.896 27.678 116.9 27.0314V26.0853C116.372 27.1716 115.53 28.0746 114.484 28.6776C113.437 29.2807 112.233 29.5562 111.029 29.4683C106.92 29.4683 104.457 26.9924 104.457 23.8427C104.457 18.658 110.627 18.1785 113.245 17.7767C115.669 17.4138 116.836 17.0508 116.836 15.4565C116.836 13.8622 115.539 13.0975 113.388 13.0975C112.539 13.0684 111.706 13.3337 111.03 13.8487C110.354 14.3638 109.878 15.0967 109.681 15.9231H105.287C105.622 14.0725 106.624 12.4089 108.102 11.2459C109.58 10.083 111.432 9.50135 113.31 9.61069ZM112.26 26.0204C114.645 26.0204 116.861 24.6076 116.861 20.2524V18.645C116.434 19.5524 115.449 20.3949 112.688 20.9393C110.186 21.4448 109.058 22.2356 109.058 23.6225C109.058 25.0094 110.147 26.0204 112.26 26.0204Z"
            fill="currentColor"></path>
        <path
            d="M124.914 10.1926H129.49V13.2775C129.832 12.2164 130.508 11.2939 131.416 10.6472C132.324 10.0004 133.417 9.66378 134.532 9.68706C135.664 9.61162 136.788 9.91623 137.728 10.5526C138.667 11.1889 139.367 12.1204 139.716 13.1997C140.089 12.1348 140.794 11.2181 141.728 10.5855C142.661 9.95279 143.774 9.63783 144.901 9.68706C149.619 9.68706 151.952 12.8757 151.952 17.4641V28.9353H147.338V18.5659C147.338 15.8439 146.612 13.6922 144.292 13.6922C141.972 13.6922 140.702 16.0253 140.702 19.9397V28.9094H136.295V18.5399C136.295 15.8179 135.828 13.6663 133.248 13.6663C130.345 13.6663 129.619 16.7901 129.619 20.4582V28.8834H124.94L124.914 10.1926Z"
            fill="currentColor"></path>
        <path
            d="M164.175 29.5327C158.511 29.5327 154.557 25.5146 154.557 19.5262C154.496 18.2317 154.703 16.9385 155.164 15.7272C155.624 14.516 156.33 13.4126 157.236 12.486C158.142 11.5593 159.229 10.8293 160.43 10.3412C161.63 9.85318 162.918 9.61761 164.214 9.64931C170.487 9.64931 173.287 14.4062 173.391 19.9539V20.8613H159.016C159.067 22.1942 159.638 23.454 160.608 24.3701C161.577 25.2862 162.867 25.7856 164.201 25.7609C165.071 25.8592 165.95 25.6897 166.722 25.2752C167.493 24.8606 168.119 24.2206 168.517 23.4407H173.17C171.978 27.1737 168.699 29.5327 164.175 29.5327ZM168.893 17.7375C168.862 16.5055 168.344 15.336 167.453 14.4855C166.561 13.635 165.368 13.173 164.136 13.2008C162.87 13.1664 161.639 13.617 160.695 14.4603C159.75 15.3036 159.164 16.4759 159.055 17.7375H168.893Z"
            fill="currentColor"></path>
        <path d="M189.538 3.03711V24.8129H201.553V28.9607H184.703V3.03711H189.538Z" fill="currentColor"></path>
        <path
            d="M212.134 9.61069C218.006 9.61069 220.183 12.9938 220.183 17.5952V24.3872C220.172 25.92 220.293 27.4509 220.546 28.9627H215.867C215.768 28.3238 215.72 27.678 215.725 27.0314V26.0853C215.198 27.1728 214.356 28.0767 213.309 28.68C212.262 29.2833 211.058 29.558 209.853 29.4683C205.744 29.4683 203.281 26.9925 203.281 23.8427C203.281 18.658 209.451 18.1785 212.056 17.7767C214.662 17.3749 215.66 17.0508 215.66 15.4565C215.66 13.8622 214.364 13.0975 212.212 13.0975C211.363 13.0684 210.53 13.3337 209.854 13.8488C209.179 14.3638 208.702 15.0967 208.505 15.9231H204.111C204.446 14.0725 205.448 12.4089 206.926 11.2459C208.404 10.083 210.257 9.50135 212.134 9.61069ZM211.084 26.0204C213.469 26.0204 215.686 24.6076 215.686 20.2524V18.645C215.258 19.5524 214.273 20.3949 211.512 20.9393C209.01 21.4448 207.883 22.2356 207.883 23.6225C207.883 25.0094 208.972 26.0204 211.084 26.0204Z"
            fill="currentColor"></path>
        <path
            d="M243.151 19.5504C243.151 25.5777 239.418 29.4273 234.337 29.4273C233.161 29.4593 232 29.1557 230.99 28.552C229.98 27.9483 229.163 27.0695 228.634 26.0184V28.9607H223.773V3.03711H228.569V13.3157C229.071 12.2049 229.89 11.2675 230.923 10.6216C231.956 9.97574 233.158 9.65035 234.376 9.68653C239.444 9.68653 243.151 13.5362 243.151 19.5504ZM233.352 25.7592C236.761 25.7592 238.537 22.9983 238.537 19.5504C238.537 16.1026 236.696 13.3806 233.352 13.3806C230.008 13.3806 228.168 16.3618 228.168 19.5504C228.168 22.7391 229.827 25.7592 233.352 25.7592Z"
            fill="currentColor"></path>
        <path
            d="M252.481 13.0962C250.913 13.0962 249.617 13.9257 249.617 15.17C249.617 16.4144 250.628 17.0495 252.663 17.5939C253.531 17.8531 254.335 18.0346 255.255 18.255C258.444 19.0845 260.556 20.6399 260.556 23.5822C260.556 27.1727 257.135 29.5317 252.715 29.5317C248.645 29.5317 245.158 27.6523 244.652 23.1416H249.228C249.266 23.5659 249.39 23.9782 249.59 24.3541C249.791 24.73 250.065 25.0619 250.396 25.3302C250.727 25.5984 251.109 25.7976 251.518 25.9159C251.927 26.0342 252.356 26.0693 252.779 26.0191C254.529 26.0191 255.981 25.2932 255.981 23.7637C255.981 22.2342 254.568 21.8454 253.142 21.4824C252.235 21.1843 251.328 20.977 250.394 20.7177C247.335 19.9141 245.274 18.1252 245.274 15.2348C245.274 11.9036 248.463 9.60938 252.572 9.60938C256.681 9.60938 259.792 11.787 260.258 15.7792H255.903C255.796 14.9736 255.378 14.2419 254.739 13.7405C254.099 13.2391 253.289 13.0079 252.481 13.0962Z"
            fill="currentColor"></path>
    </svg>
);
export const CopyIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 24 24"
        fill="#fff"
    >
            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
    </svg>
);
export const DoorSVG = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-log-out"
    >
            <path d="M11 16l4-4-4-4M15 12H3"></path>
            <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2z"></path>
    </svg>
)
